import aws from '@console/state/vuex/modules/aws';
import customer from '@console/state/vuex/modules/customer';
import nav from '@console/state/vuex/modules/nav';
import gcp from '@gcp/state';

export default {
  aws,
  customer,
  gcp,
  nav,
};
