import { Auth0Client } from '@auth0/auth0-spa-js';
import _ from 'lodash';

import env from '@shared/utilities/environment';

const audience = env.get('VITE_AUTH_AUDIENCE');
const clientId = env.get('VITE_AUTH_CLIENT_ID');
const domain = env.get('VITE_AUTH_DOMAIN');

const clientOptions = {
  domain: domain,
  client_id: clientId,
  audience: audience,
  redirect_uri: `${window.location.origin}/callback`,
  scope: 'openid profile email',
};

let authClient;
const getAuthClient = () => {
  if (authClient) {
    return authClient;
  }

  authClient = new Auth0Client(clientOptions);
  return authClient;
};

const resetAuthClient = () => (authClient = null);

function UnauthorizedError(description = 'UnauthorizedError') {
  // Example: [EMAIL_VERIFICATION_REQUIRED] Please verify your email before logging in.
  const formatPattern = /^\[(?<code>\w+)\] (?<message>.*)$/;
  const match = description.match(formatPattern);
  const instance = new Error(description);
  instance.name = 'UnauthorizedError';
  if (match) {
    instance.auth_code = match.groups.code;
    instance.auth_message = match.groups.message;
  }
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, UnauthorizedError);
  }
  return instance;
}

function LoginRequiredError() {
  const instance = new Error('Login required');
  instance.name = 'LoginRequiredError';
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, LoginRequiredError);
  }
  return instance;
}

const handleError = e => {
  if (e.error === 'login_required') {
    throw new LoginRequiredError();
  }
  if (e.error === 'unauthorized' || e.error === 'access_denied') {
    throw new UnauthorizedError(e.error_description);
  }
  throw e;
};

const login = async appState => {
  const client = getAuthClient();
  await client.loginWithRedirect({ appState });
};

const redirectToSignup = async ({ email, firstName, lastName }) => {
  const client = getAuthClient();
  const params = {
    custom_mode: 'signUp',
    signup_email: email,
    given_name: firstName,
    family_name: lastName,
  };
  await client.loginWithRedirect(params);
};

const redirectToFederatedLogin = async connectionId => {
  const client = getAuthClient();
  const params = { connection: connectionId };
  await client.loginWithRedirect(params);
};

const handleAuthenticationRedirect = async () => {
  try {
    const client = getAuthClient();
    const { appState } = await client.handleRedirectCallback();
    return _.get(appState, 'target', '/');
  } catch (e) {
    handleError(e);
  }
};

const getToken = async (opts = {}) => {
  try {
    const client = getAuthClient();
    const token = await client.getTokenSilently(opts);
    return token;
  } catch (e) {
    handleError(e);
  }
};

const getUser = async () => {
  try {
    const client = getAuthClient();
    const user = await client.getUser();
    return user;
  } catch (e) {
    handleError(e);
  }
};

const logout = () => {
  const returnTo = window.location.origin;
  const client = getAuthClient();
  client.logout({ returnTo });
};

const isAuthenticated = async () => {
  const client = getAuthClient();
  const result = await client.isAuthenticated();
  return result;
};

export default {
  login,
  redirectToSignup,
  redirectToFederatedLogin,
  handleAuthenticationRedirect,
  getToken,
  getUser,
  logout,
  isAuthenticated,
  getAuthClient,
  resetAuthClient,
};
