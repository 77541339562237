<template>
  <div>
    <img src="@console/assets/images/donut.svg" class="backgroundLogo medium top" />
    <img src="@console/assets/images/donut.svg" class="backgroundLogo large bottom" />
  </div>
</template>

<style scoped>
.backgroundLogo {
  position: fixed;
  z-index: -5;
  display: block;
  overflow: hidden;
}

.backgroundLogo.medium {
  width: 380px;
  height: 380px;
}

.backgroundLogo.large {
  width: 860px;
  height: 860px;
}

.backgroundLogo.top {
  top: -150px;
  left: 25%;
}

.backgroundLogo.bottom {
  right: -400px;
  bottom: -400px;
}
</style>
