import { core as api } from '@shared/services/api';

export class AccessValidationError extends Error {
  constructor(msg, accessValidatorResult, ...params) {
    super(msg, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AccessValidationError);
    }

    this.name = 'AccessValidationError';
    this.result = accessValidatorResult;
  }
}

export const PermissionSet = {
  SavingsAnalysis: 'SavingsAnalysis',
  Management: 'Management',
};

const handleValidationError = inner => async (...args) => {
  try {
    return await inner(...args);
  } catch (err) {
    if (err?.response?.data?.status && err?.response?.data?.permission_availability) {
      throw new AccessValidationError('Failed to validate access', err.response.data, { cause: err });
    }

    throw err;
  }
};

export const getSavingsAnalysisCustomRole = async () => {
  const res = await api.get(`/v1/gcp_permissions/savings_analysis_custom_role`);
  return res.data;
};

export const getManagementCustomRole = async () => {
  const res = await api.get(`/v1/gcp_permissions/management_custom_role`);
  return res.data;
};

export const validateBillingAccountAccess = handleValidationError(
  async (billingAccountName, permissionSet = PermissionSet.SavingsAnalysis) => {
    const body = { billing_account_name: billingAccountName, permission_set: permissionSet };
    const res = await api.post(`/v1/gcp_permissions/gcp_billing_account_access`, body);
    return res.data;
  }
);

export const validateBillingExportAccess = handleValidationError(
  async (billingAccountName, billingExportProjectId, billingExportDatasetId) => {
    const body = {
      billing_account_name: billingAccountName,
      billing_export_project_id: billingExportProjectId,
      billing_export_dataset_id: billingExportDatasetId,
    };
    const res = await api.post(`/v1/gcp_permissions/gcp_billing_export_access`, body);
    return res.data;
  }
);

export const validatePricingExportAccess = handleValidationError(
  async (billingAccountName, billingExportProjectId, billingExportDatasetId) => {
    const body = {
      billing_account_name: billingAccountName,
      billing_export_project_id: billingExportProjectId,
      billing_export_dataset_id: billingExportDatasetId,
    };
    const res = await api.post(`/v1/gcp_permissions/gcp_pricing_export_access`, body);
    return res.data;
  }
);

export const validateOrganizationAccess = handleValidationError(
  async (organizationName, permissionSet = PermissionSet.SavingsAnalysis) => {
    const body = { organization_name: organizationName, permission_set: permissionSet };
    const res = await api.post(`/v1/gcp_permissions/gcp_organization_access`, body);
    return res.data;
  }
);
