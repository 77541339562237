import _ from 'lodash';

import authService from '@console/services/authService';

const spin = () => new Promise(resolve => setTimeout(resolve, 10000));
const isInvalidState = e => e.message.toLowerCase() === 'invalid state';
const routes = {
  EMAIL_VERIFICATION_REQUIRED: 'verify_email',
};

const handle = async (error, to = '/') => {
  if (isInvalidState(error)) {
    await authService.logout();
    await spin();
    return false;
  }
  if (error.name === 'LoginRequiredError') {
    await authService.login({ target: to });
    await spin();
    return false;
  }
  if (error.name === 'UnauthorizedError') {
    const code = error.auth_code;
    const name = _.get(routes, code, 'unauthorized');
    return { name, params: { code } };
  }

  throw error;
};

export default {
  handle,
};
