import { core as api } from '@shared/services/api';

export const addServiceAccount = async () => {
  const res = await api.post(`/v1/gcp_service_account`, {});
  return res.data;
};

export const getServiceAccount = async () => {
  const res = await api.get('/v1/gcp_service_account');
  return res.data;
};
