import axios from 'axios';

import authService from '@console/services/authService';
import { core as api } from '@shared/services/api';
import env from '@shared/utilities/environment';

const client = axios.create({
  withCredentials: false,
});

const authorizationHeader = accessToken => ({
  Authorization: `Bearer ${accessToken}`,
});

const setCustomerId = customerId => {
  client.defaults.headers['X-Customer-Id'] = customerId;
};

const wait = s => new Promise(resolve => setTimeout(resolve, s * 1000));

const forceLogout = async () => {
  await authService.logout();
  await wait(5);
};

const handleError = async e => {
  const response = e.response;
  if (response && response.status === 403 && response.headers['x-user-login-required']) {
    await forceLogout();
  }
  throw e;
};

const getUrl = async path => {
  const host = env.get('VITE_API');
  return `${host}${path}`;
};

const get = async (path, options = {}) => {
  const accessToken = await authService.getToken();
  const url = await getUrl(path);
  try {
    return await client.get(url, {
      ...options,
      headers: {
        ...authorizationHeader(accessToken),
      },
    });
  } catch (e) {
    await handleError(e);
  }
};

const post = async (path, data) => {
  const accessToken = await authService.getToken();
  const url = await getUrl(path);
  try {
    return await client.post(url, data, {
      headers: {
        ...authorizationHeader(accessToken),
      },
    });
  } catch (e) {
    await handleError(e);
  }
};

const put = async (path, data) => {
  const accessToken = await authService.getToken();
  const url = await getUrl(path);
  try {
    return client.put(url, data, {
      headers: {
        ...authorizationHeader(accessToken),
      },
    });
  } catch (e) {
    await handleError(e);
  }
};

const getAwsAccounts = () => {
  return get('/v1/aws_accounts');
};

const validateAccess = awsAccountId => {
  return post(`/v1/aws_accounts/${awsAccountId}/validate_access`);
};

const addRole = awsAccountNumber => {
  return post('/v1/aws_account_iam_role', {
    aws_account_number: awsAccountNumber,
  });
};

const addManagementAccount = (awsAccountNumber, awsOrgFriendlyName) => {
  return post('/v1/aws_accounts', {
    aws_account_number: awsAccountNumber,
    aws_organization_friendly_name: awsOrgFriendlyName,
  });
};

const getIamDetails = awsAccountId => {
  return get(`/v1/aws_accounts/${awsAccountId}/iam_details`);
};

const getAwsOrganizations = () => {
  return get('/v1/aws_organizations');
};

const updateOrganizationFriendlyName = (organizationId, friendlyName) => {
  const url = `/v1/aws_organizations/${organizationId}/update_friendly_name`;
  return post(url, { friendly_name: friendlyName });
};

const requestNonComputeSavingsAnalysis = organizationId => {
  const url = `/v1/aws_organizations/${organizationId}/request_non_compute_savings_analysis`;
  return post(url, {});
};

const getSettingsSummary = organizationId => {
  return get(`/v1/aws_organizations/${organizationId}/settings_summary`);
};

const getBudget = organizationId => {
  return get(`/v1/aws_organizations/${organizationId}/budget`);
};

const getSubscription = () => {
  return get('/v1/billing/subscription');
};

const getInvoices = () => {
  return get('/v1/billing/invoices');
};

const getBillingPortal = () => {
  return get('/v1/billing/portal');
};

const getCustomer = id => {
  const customerId = id || client.defaults.headers['X-Customer-Id'];
  return get(`/v1/customers/${customerId}`);
};

const getCustomerSettings = async () => {
  const res = await api.get(`/v1/customers/settings`);
  return res.data;
};

const getCustomers = () => {
  return get(`/v1/customers`);
};

const subscribe = (
  token,
  cardholderName,
  addressLine1,
  addressLine2,
  addressCity,
  addressState,
  addressZip,
  addressCountry
) => {
  return post('/v1/billing/subscription', {
    token,
    cardholder_name: cardholderName,
    address_line1: addressLine1,
    address_line2: addressLine2,
    address_city: addressCity,
    address_state: addressState,
    address_zip: addressZip,
    address_country: addressCountry,
  });
};

const resolveAwsMarketplaceToken = token => {
  return post('/v1/billing/subscription/aws_marketplace/resolve_token', {
    token,
  });
};

const getBillingSettings = () => {
  return get('/v1/billing/settings');
};

const updateBillingSettings = additionalEmails => {
  return put('/v1/billing/settings', {
    additional_emails: additionalEmails,
  });
};

const addCustomer = companyName => {
  return post('/v1/customers', { company_name: companyName });
};

const changePassword = () => {
  return post(`/v1/users/change_password`);
};

const getSelfUser = () => {
  return get('/v1/users/self');
};

const sendVerificationEmail = () => {
  return post('/v1/users/send_verification_email');
};

const addLead = async emailAddress => {
  const url = await getUrl('/v1/users/lead');
  return client.post(url, {
    email_address: emailAddress,
  });
};

const expressInterest = async (awsOrganizationId, billingOffering) => {
  return post('/v1/users/express_interest', {
    aws_organization_id: awsOrganizationId,
    billing_offering: billingOffering,
  });
};

const getCountries = () => {
  return get('/v1/utilities/countries');
};

const getStates = () => {
  return get('/v1/utilities/states');
};

const getMonthlyReviewEmails = awsOrganizationId => {
  return get(`/v1/monthly_review_emails?aws_organization_id=${awsOrganizationId}`);
};

const getSavings = (organizationId, timeframe) => {
  let url;

  if (organizationId === 'MULTI_ORG_SUMMARY') {
    url = '/v1/customers/compute_savings_dashboard';
  } else {
    url = `/v1/aws_organizations/${organizationId}/savings_dashboard`;
  }
  if (timeframe) {
    url = `${url}?timeframe=${timeframe}`;
  }
  return get(url);
};

const getNonComputeSavings = (organizationId, service, timeframe) => {
  const url = `/v1/aws/organizations/${organizationId}/services/${service}/savings_dashboard`;
  return get(url, {
    params: {
      timeframe,
    },
  });
};

const getNonComputeSettings = (organizationId, service) => {
  const url = `/v1/aws/organizations/${organizationId}/services/${service}/settings`;
  return get(url);
};

const getCommitments = async organizationId => {
  return get(`/v1/aws_organizations/${organizationId}/commitments_dashboard`);
};

const getNonComputeCommitments = (organizationId, service) => {
  const url = `/v1/aws/organizations/${organizationId}/services/${service}/commitments_dashboard`;
  return get(url);
};

const getPrepayments = (organizationId, timeframe) => {
  let url = `/v1/aws_organizations/${organizationId}/prepayments_dashboard`;
  if (timeframe) {
    url = `${url}?timeframe=${timeframe}`;
  }
  return get(url);
};

const getPusherAuthEndpoint = () => getUrl('/v1/users/validate_pusher');

const getShowback = async (organizationId, timeframe) => {
  let url = `/v1/aws_organizations/${organizationId}/showback_dashboard`;
  if (timeframe) {
    url = `${url}?timeframe=${timeframe}`;
  }
  return get(url);
};

const getAwsServiceUsage = async organizationId => {
  return get(`/v1/aws_organizations/${organizationId}/service_usage`);
};

export default {
  getAwsServiceUsage,
  addCustomer,
  addLead,
  addManagementAccount,
  addRole,
  changePassword,
  expressInterest,
  getAwsAccounts,
  getAwsOrganizations,
  getBillingPortal,
  getBillingSettings,
  getBudget,
  getCommitments,
  getCountries,
  getCustomer,
  getCustomers,
  getCustomerSettings,
  getIamDetails,
  getInvoices,
  getMonthlyReviewEmails,
  getNonComputeCommitments,
  getNonComputeSavings,
  getNonComputeSettings,
  getPrepayments,
  getPusherAuthEndpoint,
  getSavings,
  getSettingsSummary,
  getShowback,
  getStates,
  getSubscription,
  getUrl,
  getSelfUser,
  requestNonComputeSavingsAnalysis,
  resolveAwsMarketplaceToken,
  sendVerificationEmail,
  setCustomerId,
  subscribe,
  updateBillingSettings,
  updateOrganizationFriendlyName,
  validateAccess,
};
