import type { InjectionKey } from 'vue';
import type { Store } from 'vuex';

import { createStore, useStore as baseUseStore } from 'vuex';

import * as Sentry from '@console/lib/sentry';
import modules from '@console/state/vuex/modules';
import env from '@shared/utilities/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type State = any;

// recommended implementation by vuex - https://vuex.vuejs.org/guide/typescript-support.html
export const injectionKey: InjectionKey<Store<State>> & symbol = Symbol('Vuex Store');

export const store = createStore<State>({
  modules,
  strict: env.get('NODE_ENV') !== 'production',
  plugins: [Sentry.vuexPlugin],
});

export function useVuexStore() {
  return baseUseStore<State>(injectionKey);
}
