import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import axios, { isAxiosError } from 'axios';
import _ from 'lodash';

import auth from '@console/services/authService';
import env from '@shared/utilities/environment';

export const Endpoints = {
  AZURE_API: env.get('VITE_AZURE_API'),
  CORE_API: env.get('VITE_API'),
} as const;

type BaseRequestConfig<D> = Omit<AxiosRequestConfig<D>, 'url'>;
type RequestConfig<D> = Omit<BaseRequestConfig<D>, 'method' | 'data'>;

export class ApiClient {
  readonly baseUrl: string;
  readonly client: AxiosInstance = axios.create({ withCredentials: false });

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  url(path: string): string {
    return `${this.baseUrl}${path}`;
  }

  async request<TResponse = unknown, TBody = unknown>(
    path: string,
    config: BaseRequestConfig<TBody>
  ): Promise<AxiosResponse<TResponse>> {
    const token: string = await auth.getToken();
    const request = _.merge({}, config, {
      url: this.url(path),
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    try {
      return await this.client.request<TResponse, AxiosResponse<TResponse>, TBody>(request);
    } catch (err) {
      if (isAxiosError(err)) {
        const status = err.response?.status;
        const body = err.response?.data;
        const code = body?.code;
        const errors = body?.errors;

        if (code && errors) {
          throw new ApiError(`Request failed with code ${code}`, { status, code, errors }, { cause: err });
        }
      }

      throw err;
    }
  }

  async get<TRequest = unknown, TBody = unknown>(path: string, config?: RequestConfig<TBody>) {
    return await this.request<TRequest, TBody>(path, { ...config, method: 'get' });
  }

  async post<TResponse = unknown, TBody = unknown>(path: string, data?: TBody, config?: RequestConfig<TBody>) {
    return await this.request<TResponse, TBody>(path, { ...config, method: 'post', data });
  }

  async put<TResponse = unknown, TBody = unknown>(path: string, data?: TBody, config?: RequestConfig<TBody>) {
    return await this.request<TResponse, TBody>(path, { ...config, method: 'put', data });
  }

  async delete<TResponse = unknown, TBody = unknown>(path: string, config?: RequestConfig<TBody>) {
    return await this.request<TResponse, TBody>(path, { ...config, method: 'delete' });
  }

  setCustomerId(customerId: string) {
    this.client.defaults.headers['X-Customer-Id'] = customerId;
  }
}

export class ApiError extends Error {
  readonly status: number | undefined;
  readonly code: string;
  readonly errors: Array<string>;

  constructor(
    msg: string,
    details: { status: number | undefined; code: string; errors: Array<string> },
    options?: ErrorOptions
  ) {
    super(msg, options);

    this.status = details.status;
    this.code = details.code;
    this.errors = details.errors;
  }
}

export function isApiError(error: unknown): error is ApiError {
  return !!(error as ApiError).status && !!(error as ApiError).code && !!(error as ApiError).errors;
}

export const core = new ApiClient(Endpoints.CORE_API);
export const azure = new ApiClient(Endpoints.AZURE_API);

export function setCustomerId(customerId: string) {
  [core, azure].forEach(client => client.setCustomerId(customerId));
}
