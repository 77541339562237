<script>
import errors from '@console/lib/errors';

export default {
  data() {
    return {
      loaded: false,
    };
  },
  head: {
    titleTemplate(title) {
      return title ? `${title} | ProsperOps Console` : 'ProsperOps Console';
    },
  },
  async errorCaptured(e) {
    try {
      const redirect = await errors.handle(e, this.$route.fullPath);
      if (redirect) {
        await this.$router.push(redirect);
      }
    } catch (e) {
      await this.$router.push({ name: 'error' });
    }
  },
  async mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 15000);
  },
};
</script>

<template>
  <div id="app">
    <iframe v-if="!loaded" src="/gtm" height="0" width="0" style="display: none; visibility: hidden"></iframe>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<style lang="scss">
@import 'nprogress/nprogress.css';
@import '@shared/scss/index.scss';

#nprogress .bar {
  background: map-get($theme-colors, 'secondary');
}

#app {
  width: 100%;
  height: 100%;
}
</style>
