function get<T>(key: string): T | undefined {
  try {
    const value = window.localStorage.getItem(key);
    if (!value) return undefined;
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

const set = <T>(key: string, value: T) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {}
};

const remove = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {}
};

export default {
  get,
  set,
  remove,
};
